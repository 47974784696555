import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import FullWidthImage from "../components/FullWidthImage";

// eslint-disable-next-line
export const BasicPageTemplate = ({
  topImage,
  topTitle,
  content,
  contentComponent,
}) => {
  const PageContent = contentComponent || Content;
  const image = getImage(topImage) || topImage;

  return (
    <div className="nocontent">
      <FullWidthImage
        img={image}
        title={topTitle}
        height={400}
      />
      <section className="section section--gradient">
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="section">
                <PageContent
                  className="content"
                  content={content}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

BasicPageTemplate.propTypes = {
  topImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  topTitle: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const BasicPage = ({ data }) => {
  //const { markdownRemark: post } = data;
  const { frontmatter } = data.markdownRemark;
  const { html } = data.markdownRemark;

  return (
    <Layout lang={frontmatter.lang || "en"}>
      <BasicPageTemplate
        contentComponent={HTMLContent}
        topTitle={frontmatter.title}
        topImage={frontmatter.image}
        content={html}
      />
    </Layout>
  );
};

BasicPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default BasicPage;

export const basicPageQuery = graphql`
  query basicPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        lang
      }
    }
  }
`;
